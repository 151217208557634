import React from "react"
import { graphql } from "gatsby"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import parse from "html-react-parser"
import JotformEmbed from "react-jotform-embed"

// We're using Gutenberg so we need the block styles
// these are copied into this project due to a conflict in the postCSS
// version used by the Gatsby and @wordpress packages that causes build
// failures.
// @todo update this once @wordpress upgrades their postcss version
import "../css/@wordpress/block-library/build-style/style.css"
import "../css/@wordpress/block-library/build-style/theme.css"

import Layout from "../components/layout"
import Seo from "../components/seo"

const PageTemplate = ({ data: { post } }) => {
  const featuredImage = {
    fluid: getImage(post.featuredImage?.node.localFile),
    alt: post.featuredImage?.node?.altText || post.title,
  }

  return (
    <Layout>
      <div
        className={
          post.pageACF.colorScheme + " h-[200px] featured-image-wrapper"
        }
      >
        {featuredImage?.fluid ? (
          <div className="featured-image absolute -z-20 h-[500px] md:h-[700px] lg:h-[1024px] w-full">
            <GatsbyImage
              image={featuredImage.fluid}
              alt={featuredImage.alt}
              className="!absolute -z-20 !w-screen"
            />
            <div className="absolute -z-10 h-full w-full mix-blend-screen"></div>
            <div
              className="absolute h-[200px] sm:h-[150px] md:h-[200px] lg:h-[400px] bottom-0 w-full bg-contain"
              style={{
                backgroundImage:
                  "url(https://wp.transtechsocial.org/wp-content/uploads/2023/01/white-fade-2.png)",
              }}
            ></div>
          </div>
        ) : (
          <div
            className="absolute -z-20 h-[800px] lg:h-[1024px] w-full bg-cover bg-center"
            style={{
              backgroundImage:
                "url(https://wp.transtechsocial.org/wp-content/uploads/2022/11/TransTech-Oct-29_20976-edit.jpg)",
            }}
          >
            <div className="absolute h-full w-full mix-blend-screen"></div>
          </div>
        )}
      </div>
      <div className={post.pageACF.colorScheme + " " + post.slug + " page"}>
        <div id="page-content" className="max-w-7xl mx-auto px-4 sm:px-8">
          <div className="w-full">
            <h1
              itemProp="headline"
              className="w-full font-hero text-4xl md:text-6xl lg:text-7xl leading-none text-black mb-4 md:mb-12 lg:mb-24"
            >
              {parse(post.title)}
            </h1>
          </div>

          <div className="relative flex flex-col lg:flex-row justify-between gap-8 mb-32">
            <section
              id="blocks"
              itemProp="articleBody"
              className="entry-content w-full mt-8 md:mt-0"
            >
              {!!post.content && parse(post.content)}
              {post.jotformUrl.jotformUrl && (
                <>
                  <i>
                    If the form doesn't load below,{" "}
                    <a href={post.jotformUrl.jotformUrl} target="_blank">
                      click here to go to the form
                    </a>
                  </i>
                  <div id="form">
                    <JotformEmbed src={post.jotformUrl.jotformUrl} />
                  </div>
                </>
              )}
            </section>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default PageTemplate

export const pageQuery = graphql`
  query PageById($id: String!) {
    post: wpPage(id: { eq: $id }) {
      id
      content
      title
      slug
      date(formatString: "MMMM DD, YYYY")
      jotformUrl {
        jotformUrl
      }
      pageACF {
        colorScheme
      }
      featuredImage {
        node {
          altText
          localFile {
            url
            childImageSharp {
              gatsbyImageData(
                webpOptions: { quality: 90 }
                width: 1920
                formats: WEBP
                layout: FIXED
                aspectRatio: 2
              )
            }
          }
        }
      }
      smartcrawlSeo {
        metaDesc
        title
        opengraphTitle
        opengraphDescription
        opengraphImage {
          mediaItemUrl
        }
        twitterTitle
        twitterDescription
        twitterImage {
          mediaItemUrl
        }
      }
    }
  }
`

export const Head = ({ data: { post } }) => {
  return (
    <Seo
      title={parse(post.title) + " | TransTech Social Enterprises"}
      description={post.smartcrawlSeo?.metaDesc}
      ogTitle={post.opengraphTitle}
      ogDescription={post.smartcrawlSeo?.opengraphDescription}
      twTitle={post.smartcrawlSeo?.twitterTitle}
      twDescription={post.smartcrawlSeo?.twitterDescription}
      featuredImage={post.featuredImage?.node?.mediaItemUrl}
      ogImage={post.smartcrawlSeo?.opengraphImage?.mediaItemUrl}
      twImage={post.smartcrawlSeo?.twitterImage?.mediaItemUrl}
    />
  )
}
